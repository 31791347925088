@import "./variable.scss";
@import "./global_fonts.scss";
@import "../fonts/stylesheet.css";

.min_hght_banner {
    margin-bottom: 40px;

    .bg-color {
        background-color: #f2f1ef;
    }
    .bg_img {
        background: url(../../../public/media/about-bg.webp);
        background-repeat: no-repeat;
        right: 0;
        position: relative;
        width: 100%;
        height: 590px;

        // .second {
        //   mix-blend-mode: multiply;
        //   position: absolute;
        //   left: 85px;
        //   top: 133px;
        //   z-index: 2;
        //   width: 375px;

        //   @media (max-width: 991px) {
        //     left: unset;
        //     right: 0;
        //   }
        //   @media (max-width: 576px) {
        //     width: 80%;
        //     top: unset;
        //     bottom: -20%;
        //   }
        // }

        .first {
            left: -130px;
            top: calc(50% - 185.5px);
            position: absolute;
            z-index: 4;
            height: 371px;
            width: auto;

            @media screen and (max-width: 991px) {
                right: 0;
                left: unset;
            }
            @media screen and (max-width: 767px) {
                right: auto;
                left: -100px;
            }
            @media screen and (max-width: 576px) {
                width: 100%;
                height: auto;
                left: -50px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        @media screen and (max-width: 767px) {
            width: 80%;
            margin-right: 0;
            margin-left: auto;
        }
        @media screen and (max-width: 576px) {
            height: 100vw;
            background-position: center;
        }
    }

    .left_banner {
        padding-right: 161px;
        height: 100%;
        display: flex;
        align-items: center;
        //  padding-top: 40px;

        h2 {
            font-weight: 800;
            font-size: 35px;
            line-height: 39px;
            margin-bottom: 24px;
            text-align: left;
            color: #2a0a4d;
            margin-bottom: 24px;
            text-align: left;
        }

        p {
            font-weight: 500;
            //  font-size: 20px;
            line-height: 30px;
            color: #1a1919;
            text-align: left;

            // @media (max-width: 768px) {
            //   text-align: center;
            // }
        }

        button {
            display: flex;
            justify-content: center;
            // padding: 14px 24px 14px 32px;
            // gap: 12px;
            background: #2a0a4d;
            // border-radius: 32px;
            font-size: 15px;
            width: 55%;
            transition: background-color 0.3s ease, color 0.3s ease;
            &:focus {
                background-color: #53119b;
                color: white;
                outline: none;
            }

            svg {
                fill: #e2e54e !important;
            }

            &:hover {
                color: #fff;
                background: #2a0a4d;

                svg {
                    fill: #e2e54e !important;
                }
            }

            @media (max-width: 575px) {
                display: flex;
                justify-content: center;
                font-size: 15px;
                width: 100%;
            }
            // @media (max-width: 428px) {
            //    width:100%;
            //   //  display: flex;
            //   //  justify-content: center;
            //      font-size: 15px;
            // }
        }
        @media screen and (max-width: 991px) and (min-width: 768px) {
            display: block;
            padding-top: 40px;
            padding-left: 32px;
        }
        @media (max-width: 767px) {
            padding-right: 0 !important;
        }
    }

    @media (max-width: 428px) {
        margin-bottom: 80px;
        background-color: #f8f8f8;
    }

    a {
        text-decoration: none;
    }
}
