@import "./variable.scss";
@import "./global_fonts.scss";
@import "../fonts/stylesheet.css";
.instant-offer-wrapper {
    background-image: url("../../../public/media/sell-my-car-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 120px;
    padding-bottom: 120px;
    margin-bottom: 70px;
    > h1 {
        color: #fff;
    }
    .form-wrapper {
        max-width: 600px;
        background-image: url("../../../public/media/get-your-price-bg.png");
        padding: 40px;
        border-radius: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
        select,
        input {
            border: 1px solid #2a0a4d;
            border-radius: 25px;
            display: block;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
        }
        h2 {
            // font-size: 32px;
            font-weight: 900;
        }
    }
}

.question-mark {
    cursor: pointer;
    margin-left: 5px;
    color: #fff;
    background: #53119b;
    padding: 5px;
    clip-path: circle();
    right: 25px;
    top: 2px;
    position: absolute;
}

.divider {
    font-size: 20px;
    display: flex;
    align-items: center;
}

.divider::before,
.divider::after {
    flex: 1;
    content: "";
    padding: 0.2px;
    background-color: #919ba6;
    margin: 5px;
}

@media screen and (max-width: 428px) {
    // .form-wrapper
    .mobile {
        gap: 12px;
    }
    .mobile select,
    .mobile > div {
        width: 100%;
    }
    .instant-offer-wrapper .form-wrapper {
        width: 300px;
        padding: 20px;
    }
}
