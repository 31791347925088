@import "../fonts/stylesheet.css";

.aboutSmoothRelaxRide {
  .row {
    .happinees_guaranteed {
      position: relative;
      .image-demensions {
        height: 100%;
        object-fit: cover;

        // width: 90%;

        // @media screen and (max-width: 1024px) and (min-width:576px) {
        //     display: none;
        // }

        @media (max-width: 575px) {
          width: 100%;
        }
        @media screen and (min-width: 576px) {
          width: 100%;
          height:100%;
        }
      //   @media screen and (max-width: 991px) {
      //     width: 100%;
      //     height:100%;
      // }
      }

      // .image-demension {
      //     width: 100px;
      //     height: 100px;
      //     position: absolute;
      //     right: -30px;
      //     left: auto;
      //     top: 49px;

      //     @media screen and (max-width: 1024px) and (min-width:768px) {
      //         display: none;
      //     }

      //     @media (max-width:820px) and (min-width:576px) {
      //         display: none;

      //     }

      //     @media (max-width:768px) {
      //         width: 55px;
      //         height: 100px;
      //         position: absolute;
      //         right: left;
      //         left: 66%;
      //         top: -6px;

      //     }

      //     @media (max-width:575px) {
      //         width: 100px;
      //         height: 60px;
      //         left: 66%;
      //         top: 12px;
      //     }
      // }

      // .tab-mode {
      //     display: none;

      //     @media only screen and (max-width:1024px) and (min-width:576px) {
      //         display: block;
      //         margin: auto;

      //     }

      //     @media only screen and (max-width:767px) and (min-width:576px) {
      //         width: 100%;

      //     }
      // }
      @media screen and (max-width: 575px) {
        padding: 0;
      }
    }

    .col-sm-6 {
      .paragraph-padding {
        padding-left: 71px !important;

        @media (max-width: 575px) {
          padding: 0;
        }
      }

      p {
        width: 100%;
        text-align: justify;
        line-height: 1.2;

        @media (max-width: 575px) {
          width: 100%;
        }
      }

      h2 {
        width: 100%;

        @media (max-width: 575px) {
          width: 100%;
        }
      }

      .btn-width-about {
        width: 50%;

        @media (max-width: 991px) {
          width: 100%;
        }
      }
    }

    @media (max-width: 575px) {
      padding: 0.5rem;
    }
  }
  @media screen and (max-width: 1199px) {
    width: 90vw;
    max-width: unset;
  }
}

// .image-demensions {
//     position: absolute;
//     left: 0;
//     width: 100%;
//     height: 100%;

//     @media (max-width:820px) and (min-width:576px) {
//         display: none;

//     }

//     @media (max-width:575px) {
//         position: relative;

//     }

// }

.paragraph-padding {
  padding-left: 150px;
  padding-right: 80px;

  // @media (min-width:2925px) {
  //     padding-left: 80px;
  //     padding-right: 50px;
  // }
  // @media (max-width:1399px) {
  //     padding-left: 100px;
  //     padding-right: 50px;
  // }
  @media (max-width: 1199px) {
    padding-left: 20px;
    padding-right: 10px;
    h2 {
      font-size: 30px;
    }
  }
  // @media (max-width:1024px) {
  //     padding-right: 50px;
  //     padding-left: 50px;
  // }
  // @media (max-width:767px) {
  //     padding-right: 0px;
  //     padding-left: 0px;
  //     h2{
  //         font-size: 25px;
  //     }
  // }
}
