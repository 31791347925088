.How_it_works_banner {
    .work-banner {
        position: relative;
        // img {
        //   width: 100%;
        //   object-fit: cover;
        //   height: 100%;
        //   // margin-top: -82px;
        // }
        Image {
            width: 100%;
            object-fit: cover;
            height: 100%;
            // margin-top: -82px;
        }

        h2 {
            position: absolute;
            left: 0;
            right: 0;
            top: calc(40% - 24.5px);
            font-size: 49px;
            white-space: nowrap;
            z-index: 1;

            @media (max-width: 768px) {
                // font-size: 34px;
                margin-top: 40px;
            }

            @media (max-width: 425px) {
                font-size: 34px;
                // margin-top: 40px;
            }
        }

        a {
            position: absolute;
            left: 50%;
            right: 50%;
            top: calc(62% - 24.5px);
            width: auto;
            width: 240px;
            transform: translate(-50%, -50%);

            @media (max-width: 768px) {
                // font-size: 34px;
                margin-top: 32px;
            }

            @media (max-width: 425px) {
                // margin-top: 40px;
            }
        }
    }

    .right-side {
        display: flex;
        align-items: center;

        .left_banner {
            text-align: left;
            padding: 0px 20px;

            @media (max-width: 425px) {
                padding: 0 25px !important;
            }

            h2 {
                font-size: 30px;

                @media (max-width: 768px) {
                    // font-size: 34px;
                    margin-top: 40px;
                }
            }

            p {
                text-align: left;
                font-size: 18px;
                margin-top: 20px;
                margin-bottom: 20px;
                line-height: 30px;
                font-weight: 500;
            }

            .custom_btn {
                font-size: 15px;
                // width: 75%;

                @media (max-width: 425px) {
                    width: 100%;
                }
            }
        }
    }

    .video-container {
        position: relative;
        width: 100%;
        // height: auto;
        height: 800;
    }

    .thumbnail-container {
        position: relative;
        cursor: pointer;
    }
    .play-button {
        position: absolute;
        top: 60%;
        left: 50%;
        background-color: #fff;
        clip-path: circle();
        cursor: pointer;
        transform: translate(-50%, -50%);
        svg {
            height: 70px;
            padding: 16px;
            margin-left: 7px;
        }
    }
}
