@import "./variable.scss";
@import "./global_fonts.scss";
@import "../fonts/stylesheet.css";

.aboutPersonalShopper {

    h2 {
          font-size: 35px;
          padding-bottom: 30px;

      }
  @media screen and (max-width: 575px) {
    //   width: 100% !important;
    padding: 4px 10px 0px 10px; // top, right, bottom , left
    margin-top: unset;
    .shopper-btn {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      padding: 8px 10px 8px 10px;
      font-size: 15px;
    }
  }

    @media screen and (max-width: 991px) {
      font-size: 35px;
      width: 100%;
      margin-top: 0px;

    }
    @media screen and (min-width: 1090px) {
      padding-bottom: 60px;
      font-size: 35px;
      padding-top: 6rem;
    }
  }

.shopper-btn {
  font-family: 'Avenir-Roman';
  background-color: map-get($color, primary-1);
//  font-size: map-get($font-size, small);
  border-radius: 25px;
  color: white;
  width: 340px;

  svg {
    path {
      fill: map-get($color, border-color);
    }
  }
  @media screen and (max-width: 428px) {
     font-size: 35px;
    width:100%;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  @media screen and (max-width: 3000px) {
    // font-size: medium;
  }
  @media screen and (max-width: 1600px) {
    // font-size: medium;
  }

  @media screen and (max-width: 1090px) {
    // font-size: small;
  }

  @media screen and (max-width: 860px) {
    svg {
      width: 20px;
      height: 20px;
    }
    font-size: 15px;
  }
}
