@import "./variable.scss";
@import "./global_fonts.scss";

.sell {
  padding: 67px;

  .left_banner {
    h2 {
      text-align: left;
      font-size: 30px;
    }

    .border_bottom {
      border-bottom: 4px solid #e2e42b;
      margin-bottom: 13px;
      width: 54%;

      @media (max-width: 428px) {
        width: 95%;
      }
    }

    p {
      text-align: left;
      font-size: 18px;
      line-height: 26px;
      width: 80%;

      @media (max-width: 425px) {
        width: 100%;
      }
    }

    button {
      // width: 50%;
      text-align: center;
      font-size: 15px;
      margin-top: 20px;

      @media (max-width: 425px) {
        width: 100%;
      }
    }
  }

  .right_banner {
    border-left: 2px solid #e2e42b;
    padding: 48px 0 30px 68px;

    h2 {
      text-align: left;
      font-size: 30px;
    }

    p {
      text-align: left;
      text-align: left;
      font-size: 18px;
      line-height: 26px;
    }

    @media (max-width: 991px) {
      border-left: none;
      padding: 30px 0;
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 62px;
  }

  @media (max-width: 575px) {
    padding: 37px;
  }
}
