@import "./variable.scss";
@import "./global_fonts.scss";
@import "../fonts/stylesheet.css";

.topMenu a {
    display: block;
    padding: 0.3125rem 0.25rem;
}

body {
    margin: 0;
    font-family: "Avenir-Heavy" !important;
    // text-align: center !important;
    @media (min-width: 1445px) {
        max-width: 1440px !important;
        padding: 0 !important;
        margin: auto !important;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body.menu-open {
    // overflow: hidden;
    touch-action: none;
    -ms-touch-action: none;
    position: absolute;
    height: 100vh;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    pointer-events: none;
    // overflow: hidden;
}

.sticky-nav {
    position: sticky !important;
    top: 0;
    background-color: white;
    z-index: 20;
    border: 0 solid #e2e8f0;
    box-shadow: 0 18px 15px -11px rgb(0 0 0 / 20%);
}
.navbar {
    font-family: "Avenir-Light";
    z-index: 1005;
    position: relative;

    .tab_sign_in {
        display: none;

        @media screen and (max-width: 991px) {
            display: flex;
        }
    }

    .mobile-view {
        @media screen and (max-width: 576px) {
            flex-direction: column;
        }
    }

    .navbar-toggler {
        border: none;
        box-shadow: none;
        border-radius: 0;
        transition: transform 0.3s ease-in-out;
        .navbar-toggler-icon {
            background-image: url("../../../public/media/menu.svg");
            height: 30px;
            width: 30px;
        }
    }

    .navbar-brand {
        @media screen and (max-width: 991px) {
        }
    }

    // .citydropdown {
    //   color: #000000;
    //   text-decoration: none;
    // }

    .contect-number {
        @media screen and (min-width: 991px) {
            display: none;
        }
    }

    .searchCars {
        display: none;

        @media screen and (max-width: 991px) {
            display: block;
        }
    }

    .mob-menu {
        @media screen and (min-width: 992px) {
            display: none;
        }
    }

    .navbar-nav {
        .nav-item {
            margin-left: 1.5rem;
            margin-right: 0.5rem;

            .dropdown-menu {
                border: none;
                @media screen and (min-width: 992px) {
                    border-radius: 5px;
                    padding-top: 0;

                    padding-bottom: 0;
                    border: 1px solid transparent;
                    box-shadow: 0px 0px 30px #e8e8e8;
                }
                @media screen and (max-width: 991px) {
                    margin-left: -30%;
                    width: 100%;
                }

                li {
                    @media screen and (min-width: 992px) {
                        // padding: 8px 0;
                        font-size: 14px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.09019607843137255);
                        // a {
                        //     border-bottom: 1px solid rgba(0, 0, 0, .09019607843137255);
                        // }

                        &:last-child {
                            border-bottom: none;
                        }

                        a {
                            padding: 8px 20px;
                            font-size: 13px;
                            &:active {
                                background-color: unset;
                            }
                        }
                    }
                    a {
                        &:active {
                            background-color: unset;
                        }
                    }
                }
            }
            //   .dropdown-menu{
            //       border: none;
            //       padding-left: 30px;
            //   }
            .nav-link,
            .dropdown-item {
                color: black;
                opacity: 0.8;
                @media screen and (max-width: 991px) {
                    color: #2a0a4d;
                    font-weight: 800 !important;
                }
            }
        }

        .signIn {
            img {
                @media screen and (max-width: 991px) {
                    display: none;
                }
            }
            .signInHamburger {
                @media screen and (max-width: 991px) {
                    display: none;
                }
            }

            .d-flex-web {
                margin-top: 5px;
            }
        }
    }

    a {
        text-decoration: none;

        p {
            color: #2a0a4d;
        }
    }
}

@media screen and (max-width: 991px) {
    .navbar-collapse.show {
        position: fixed;
        padding-top: 24px;
        left: 0px;
        top: 0px;
        bottom: 0px;
        transition: left 0.1s ease-in-out;
    }

    .navbar-collapse {
        z-index: 100;
        // position: fixed;
        text-align: left;

        position: absolute;
        left: -100vw;
        width: 90vw;
        background-color: white;

        .mob-menu {
            position: relative;
            // margin-bottom: 36px;

            .close {
                position: absolute;
                top: calc(50% - 12px);
                right: 10%;
            }
        }
    }

    .searchCars {
        // padding-left: 20px;
        color: #e2e42b !important;
    }

    .navbar-nav {
        width: 100%;
        //   padding-left: 40px;
        // height: 75vh;
        // overflow-y: scroll;
        li {
            font-family: "Avenir-Black";
            font-weight: 800;
            font-size: 24px;
        }
    }

    .nav-link {
        padding: 10px;
    }
    .visibleHide {
        visibility: hidden;
    }
}
.copyrights {
    @media screen and (min-width: 992px) {
        display: none;
    }
    @media screen and (max-width: 991px) {
        display: block;
        position: absolute;
        bottom: 0;
        padding-left: 30px;
        opacity: 0.5;
        font-size: 16px;
    }
    @media screen and (max-width: 576px) {
        font-size: 14px;
    }
}

.logo-image {
    width: 75%;
    height: 75%;
    @media (max-width: 991px) {
        width: 115%;
        height: 115%;
    }
    @media (min-width: 1440px) {
        width: 85%;
        height: 85%;
    }
}

.dropdown-toggle {
    margin-left: 7px;
    border: none;
    outline: none;
    box-shadow: none;
}
