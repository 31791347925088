@import "./variable.scss";
@import "./global_fonts.scss";
@import "../fonts/stylesheet.css";

.get-my-offer-wrapper > h2 {
    font-weight: 900;
}
.grid-slider-section {
    overflow: hidden;

    .padding-right {
        padding-right: 116px;

        @media (max-width: 768px) {
            padding-right: 0 !important;
        }
    }

    .right {
        .tab-pane {
            // padding: 42px;
            padding: 28px;
            // padding: 40px 20px 20px 20px;
            height: auto;
            width: 100%;
            border-radius: 8px;
            // background-image: linear-gradient(to bottom, #d0e194, #bce2c9, #b0e6d5);
            margin-right: 12px;
            border-radius: 20px;
            position: relative;
            h2 {
                font-size: 24px;
            }
            & {
                @media (max-width: 575px) {
                    margin-right: 0;
                }
            }

            p {
                font-size: 18px;
                text-align: left;
                font-weight: 400;
                line-height: 27.32px;
                color: #1a1919;
                // background: linear-gradient(0deg, #1a1919, #1a1919),
                //     linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));

                // font-family: "Avenir";
            }

            @media (max-width: 912px) and (min-width: 576px) {
                & {
                    // display: flex;
                    height: 144px;

                    .tablet-width {
                        width: 50%;
                    }

                    svg {
                        right: 38px;
                    }

                    p {
                        text-align: left;
                        font-size: 15px;
                        padding-left: 10px;

                        width: 50%;
                    }
                }
            }
        }
    }

    @media (max-width: 575px) {
        padding: 33px 6px;
    }
}

.desktop-display-slider {
    display: flex;

    @media (max-width: 991px) {
        flex-direction: column;
    }
    #menu2,
    #home {
        background-image: url("../../../public/media/get-your-price-bg.png");
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
    }
    #menu3,
    #menu1 {
        background-color: #e2e42b;
    }

    #home,
    #menu1,
    #menu2,
    #menu3 {
        margin-bottom: 24px;
        position: relative;

        @media screen and (max-width: 991px) {
            display: flex;
            align-items: center;
            height: auto;
            min-height: 186px;
        }
    }
    .mobile-heading {
        text-align: left;
        // padding-left: 10px;

        @media screen and (min-width: 576px) {
            display: none;
        }
    }

    .tab-pane {
        height: 350px;
        // width: 285px !important;
        border-radius: 8px;
        // background-image: url(http://localhost:3000/static/media/card_bg.37438d1….png);
        margin-right: 25px !important;

        .bg_slider {
            .svg-class {
                position: absolute;
                bottom: 10%;
                right: 10%;
            }
            @media screen and (max-width: 991px) {
                //  position: relative;
                width: 100%;
                p {
                    width: 75%;
                    margin: 0;
                }
                .svg-class {
                    position: absolute;
                    top: calc(50% - 38.5px);
                    bottom: unset;
                    //  top: 5%;
                    right: 5%;
                }
            }
            @media screen and (max-width: 576px) {
                position: relative;
                p {
                    width: 75%;
                }
                .svg-class {
                    position: relative;
                    float: right;
                }
            }
        }
    }
}

.grid-slider-section + .custom_btn {
    @media only screen and (max-width: 575px) {
        min-width: 240px !important;
        width: auto !important;
    }
}

@media screen and (max-width: 428px) {
    .bg_slider svg {
        width: 48px;
    }
}
