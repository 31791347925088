@import "./variable.scss";
@import "./global_fonts.scss";
@import "../fonts/stylesheet.css";
.sell-car-wrapper {
    background-color: #2a0a4d;
    padding: 70px 0;
    .heading-one {
        font-size: 34px;
        color: #fff;
    }
    .heading-two {
        font-size: 48px;
        color: #fff;
    }
    .sell-anywhere-divider {
        width: 200px;
        height: 5px;
        background-color: #e2e42b;
        margin: auto;
    }
    .public-info > .bg-div {
        background-image: url("../../../public/media/public-info-bg-1.png");
        border-radius: 18px;
        padding: 12px 0;
        .d-flex.justify-content-center.align-items-center {
            gap: 14px;
        }
    }

    .public-info > .bg-div:nth-child(2) {
        background-color: #e2e42b;
        border-radius: 18px;
        padding: 12px 0;
        background-image: unset;
        .d-flex.justify-content-center.align-items-center {
            gap: 14px;
        }
    }
    .public-info {
        gap: 20px;
        @media only screen and (max-width: 768px) {
            padding: 0 4rem;
        }

        @media only screen and (max-width: 834px) and (min-width: 767px) {
            .bg-div {
                width: 26.666667%;
            }
        }
        @media only screen and (max-width: 768px) and (min-width: 576px) {
            .bg-div {
                width: 44.333333%;
            }
        }

        p {
            font-size: 16px;
            color: #2a0a4d;
            margin: 0;
            text-align: left;
        }
        strong {
            font-size: 18px;
            color: #1a1919;
            margin: 0;
            text-align: left;
        }
    }
    .custom_btn:active,
    .custom_btn:focus,
    .custom_btn {
        background-color: #e2e42b;
        color: #2a0a4d;
        @media only screen and (max-width: 575px) {
            min-width: 240px !important;
            width: auto;
        }
    }
    .sell-anywhere-divider + .container {
        max-width: 100%;
    }
    .map-img {
        @media only screen and (max-width: 768px) {
            object-fit: contain;
            margin: 0 !important;
            width: 100%;
        }
    }

    .custom_btn {
        @media only screen and (max-width: 575px) {
            min-width: 240px !important;
            width: auto !important;
        }
    }

    @media screen and (max-width: 428px) {
        .heading-one {
            font-size: 20px;
        }
        .heading-two {
            font-size: 22px;
        }
    }
}
